

















import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import Plyr from 'plyr'
import {CommentModule, DrawModule, ProjectModule, VideoModule} from '@/store'
import {emitter, Events} from '@/common/events'
import {Handler} from 'mitt'
import log from 'loglevel'
import {captureVideoImage, getDuration, getVideoSource} from '@/common/player'

@Component({})
export default class PlayerComponent extends Vue {
  @Prop() link!: string
  @Ref('plyr') readonly plyr!: { player: Plyr }

  mounted(): void {
    this.plyr.player.on('ready', this.eventListener)
  }

  beforeDestroy(): void {
    VideoModule.setPlayerReady(false)
    this.plyr.player.off('ready', this.eventListener)

    emitter.off(Events.VIDEO_PLAY, this.handlerPlayVideo)
    emitter.off(Events.VIDEO_PAUSE, this.handlerPauseVideo)
    emitter.off(Events.VIDEO_CHANGE_TIME, this.handlerChangeTime)
    emitter.off(Events.VIDEO_CHANGE_VOLUME, this.handleChangeVolume)
  }

  get handlerPlayVideo(): Handler {
    return () => this.player.play()
  }

  get handlerPauseVideo(): Handler {
    return () => this.player.pause()
  }

  get handlerChangeTime(): Handler<{ time: number }> {
    return (event?: { time: number, pause?: boolean }): void => {
      if (event?.pause) {
        this.player.pause()
      }
      if (event?.time) {
        this.player.currentTime = event.time
      }
    }
  }

  get handleChangeVolume(): Handler<{ volume: number }> {
    return (event?: { volume: number }): void => {
      if (!event) {
        return
      }
      this.player.volume = event.volume
    }
  }

  get eventListener() {
    return async (event: Plyr.PlyrEvent): Promise<void> => {
      log.debug('player eventListener ready')
      const instance = event.detail.plyr

      const duration = await getDuration(instance)
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      const currentTime = instance.media.currentTime

      VideoModule.setDuration(Math.round(duration))
      VideoModule.setCurrentTime(Math.round(currentTime))
      VideoModule.setVolume(instance.volume)
      VideoModule.setPlayerStatus('ready')
      VideoModule.setPlayerReady(true)

      emitter.on(Events.VIDEO_PLAY, this.handlerPlayVideo)
      emitter.on(Events.VIDEO_PAUSE, this.handlerPauseVideo)
      emitter.on(Events.VIDEO_CHANGE_TIME, this.handlerChangeTime)
      emitter.on(Events.VIDEO_CHANGE_VOLUME, this.handleChangeVolume)
    }
  }

  @Watch('link', {immediate: true})
  public handleLinkChange(val: string, oldVal: string): void {
    if (!val) {
      return
    }
    try {
      const url = new URL(val)
      this.setSource(url)
    } catch (e) {
      log.error('Could not convert link to URL: ' + val)
    }
  }

  get player(): Plyr {
    return this.plyr.player
  }

  public async setSource(link: URL): Promise<void> {
    this.player.source = await getVideoSource(link)
  }

  private handlePlaying(): void {
    log.debug('playing video')
    VideoModule.setPlayerStatus('playing')
    const mode = ProjectModule.canvasMode
    switch (mode) {
      case 'activated-draw':
        DrawModule.stopAnnotating() // will trigger storeDrawing
        break
      case 'activated-comment':
        CommentModule.stopAnnotating() // will trigger storeComment
        break
    }
  }

  private handlePause(): void {
    log.debug('pausing video')
    VideoModule.setPlayerStatus('pause')
  }

  private handleTimeUpdate($event?: { timeStamp: number }): void {
    if (!this.player) {
      return
    }
    const time = Math.round(this.player.currentTime)
    VideoModule.setCurrentTime(time)
  }
}
