import {AnnotationType} from '@/store/models/AnnotationType'

export interface TimeStampMessage {
  id: string
  type: AnnotationType
  text: string
  userName: string
  timeInSeconds: number
}

export interface Comment extends TimeStampMessage {
  type: 'comment'
}

export interface Drawing extends TimeStampMessage {
  type: 'drawing'
  snapshot: string
  dataUrl: string
}

export interface Clip {
  id: string
  title: string
  link: string
  comments: Comment[]
  drawings: Drawing[]
}

export const isDrawingMessage = (t: TimeStampMessage): t is Drawing => {
  return t.type === 'drawing'
}
