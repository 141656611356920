



























import {Component, Vue} from 'vue-property-decorator'
import {AppModule, ProjectModule} from '../store'
import {Clip} from '@/store/models/Project'

@Component({})
export default class ClipTitleBarComponent extends Vue {
  public titleModel = ''
  public titleEditMode = false

  get clip(): Clip | undefined {
    return ProjectModule.currentClip
  }

  get clipId(): string {
    return ProjectModule.currentClipId
  }

  get title(): string {
    return this.clip?.title || ''
  }

  set title(title: string) {
    ProjectModule.setTitle({clipId: this.clipId, title})
  }

  public toggleTitleEdit(): void {
    this.titleEditMode = !this.titleEditMode
    if (this.titleEditMode) {
      this.titleModel = this.title
    }
  }

  public saveTitle(): Promise<void> {
    return ProjectModule.setTitle({clipId: this.clipId, title: this.titleModel})
      .then(() => this.toggleTitleEdit())
  }

  public toggleModal(): void {
    AppModule.setShareModelOpen(!AppModule.shareModelOpen)
  }
}
