





























import {Component, Vue} from 'vue-property-decorator'
import {CommentModule, ProjectModule, VideoModule} from '@/store'
import {formatTime, relativeTime} from '@/common/utils'
import {colorClasses} from '@/common/styles'
import {Comment, Drawing} from '@/store/models/Project'
import {handleStopDrawingAnnotate} from '@/common/events'
import {handleCommentAnnotationClick, handleDrawAnnotationClick} from '@/components/player/ToggleAnnotations'

interface DrawingExtended extends Drawing {
  left: string
}

interface CommentExtended extends Comment {
  left: string
}

@Component({})
export default class PlayerProgressBarComponent extends Vue {
  public maxSeconds = '00'
  public maxMinutes = '00'
  public progress = 0
  public loadingProgressBar = false

  public baseStyle = {
    top: '-10px'
  }

  mounted(): void {
    const time = formatTime(VideoModule.duration)
    this.maxSeconds = time.seconds
    this.maxMinutes = time.minutes
  }

  public colors(userName: string): { [key: string]: boolean } {
    return colorClasses(userName)
  }

  get currentTime(): { seconds: string, minutes: string } {
    return formatTime(VideoModule.currentTime) || {seconds: '00', minutes: '00'}
  }

  get drawingAnnotations(): DrawingExtended[] {
    if (!ProjectModule.currentClip) {
      return []
    }
    return ProjectModule.currentClip.drawings.map(d => ({
      ...d,
      left: `${relativeTime(d.timeInSeconds, VideoModule.duration)}%`
    }))
  }

  get commentAnnotations(): CommentExtended[] {
    if (!ProjectModule.currentClip) {
      return []
    }
    return ProjectModule.currentClip.comments.map(d => ({
      ...d,
      left: `${relativeTime(d.timeInSeconds, VideoModule.duration)}%`
    }))
  }

  get playerProgress10K(): number {
    return 10000.0 * VideoModule.currentTime / VideoModule.duration
  }

  /**
   * Only change time when not in edit mode
   */
  set playerProgress10K(p: number) {
    switch (ProjectModule.canvasMode) {
      case 'activated-draw':
        this.loadingProgressBar = true
        handleStopDrawingAnnotate().finally(() => {
          this.loadingProgressBar = false
        })
        break
      case 'activated-comment':
        this.loadingProgressBar = true
        CommentModule.stopAnnotating().finally(() => {
          this.loadingProgressBar = false
        })
        break
      case 'deactivated': {
        const currentTime = p / 10000.0 * VideoModule.duration
        VideoModule.changeCurrentTime({time: currentTime})
        break
      }
    }
  }

  public handleDrawAnnotationClicked(drawing: Drawing): Promise<void> {
    return handleDrawAnnotationClick(drawing)
  }

  public handleCommentAnnotationClicked(comment: Comment): Promise<void> {
    return handleCommentAnnotationClick(comment)
  }
}
