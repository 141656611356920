





import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {fabric} from 'fabric'
import {Drawing} from '@/store/models/Project'
import log from 'loglevel'

@Component({})
export default class DrawingCanvasReadOnlyComponent extends Vue {
  @Prop() height!: number
  @Prop() width!: number
  @Ref('drawingCanvasRo') canvasRef!: HTMLCanvasElement
  @Prop() drawing!: Drawing

  canvas: fabric.Canvas | undefined

  mounted() {
    this.canvas = new fabric.Canvas(this.canvasRef, {
      selection: false
    })
    this.restoreSnapshot(this.canvas, this.drawing.snapshot)
  }

  destroyed() {
    log.debug('clearing snapshot read-only')
  }

  private restoreSnapshot(canvas: fabric.Canvas, snapshot: string): any {
    log.debug('restoring snapshot read-only')
    canvas.clear()
    canvas.loadFromJSON(snapshot, canvas.renderAll.bind(canvas), (o: any, object: any) => {
      object.set('selectable', false)
    })
  }
}
