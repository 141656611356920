import {Comment, Drawing} from '@/store/models/Project'
import {CommentModule, DrawModule, ProjectModule, VideoModule} from '@/store'
import {handleStopDrawingAnnotate} from '@/common/events'
import {addjustTime} from '@/common/utils'

export const handleDrawAnnotationClick = async ({timeInSeconds, id}: Drawing): Promise<void> => {
  const canvasMode = ProjectModule.canvasMode
  switch (canvasMode) {
    case 'deactivated':
      break
    case 'activated-comment':
      await CommentModule.stopAnnotating()
      break
    case 'activated-draw':
      await handleStopDrawingAnnotate()
      break
  }

  // adjust time to prevent failing to change videotime if time = 0s
  VideoModule.changeCurrentTime({time: addjustTime(timeInSeconds)})
  await DrawModule.startAnnotatingFromSnapshot(id)
}

export const handleCommentAnnotationClick = async ({timeInSeconds, id}: Comment): Promise<void> => {
  const canvasMode = ProjectModule.canvasMode
  switch (canvasMode) {
    case 'deactivated':
      break
    case 'activated-comment':
      await CommentModule.stopAnnotating()
      break
    case 'activated-draw':
      await handleStopDrawingAnnotate()
      break
  }

  // adjust time to prevent failing to change videotime if time = 0s
  VideoModule.changeCurrentTime({time: addjustTime(timeInSeconds)})

  // TODO: move this logic down to CommentModule (just like DrawModule)
  CommentModule.setCurrentCommentId({commentId: id})
  const text = ProjectModule.currentClip?.comments.find(c => c.id === id)?.text || ''
  CommentModule.setCurrentComment({comment: text})
  ProjectModule.setCanvasMode('activated-comment')
}
