

















import {Component, Vue, Watch} from 'vue-property-decorator'
import {formatTime} from '@/common/utils'
import {VideoModule} from '@/store'

@Component({})
export default class PlayerControlsComponent extends Vue {
  public maxSeconds = '00'
  public maxMinutes = '00'
  public progress = 0

  get duration(): number {
    return VideoModule.duration
  }

  get isPlaying(): boolean {
    return VideoModule.isPlaying
  }

  get currentTime(): { seconds: string, minutes: string } {
    return formatTime(VideoModule.currentTime) || {seconds: '00', minutes: '00'}
  }

  get playerProgress(): number {
    return 100.0 * VideoModule.currentTime / VideoModule.duration
  }

  set playerProgress(p: number) {
    const currentTime = p / 100.0 * VideoModule.duration
    VideoModule.changeCurrentTime({time: currentTime})
  }

  get soundVolume(): number {
    return 100.0 * VideoModule.currentVolume
  }

  set soundVolume(volumePercent: number) {
    VideoModule.changeVolume(volumePercent / 100.0)
  }

  public handlePlay(): void {
    VideoModule.playVideo()
  }

  public handlePause(): void {
    VideoModule.pauseVideo()
  }

  public handleVolumeToggle(): void {
    VideoModule.toggleMute()
  }

  @Watch('duration', {immediate: true})
  public setMaxTime(duration: number): void {
    const time = formatTime(duration)
    this.maxSeconds = time.seconds
    this.maxMinutes = time.minutes
  }
}
