



































import {Component, Vue} from 'vue-property-decorator'
import {isDrawingMessage, TimeStampMessage} from '@/store/models/Project'
import {ProjectModule} from '@/store'
import {AnnotationType} from '@/store/models/AnnotationType'
import {colorClasses} from '@/common/styles'
import {isCommentAnnotation, isDrawingAnnotation} from '@/common/utils'
import {handleCommentAnnotationClick, handleDrawAnnotationClick} from '@/components/player/ToggleAnnotations'
import log from 'loglevel'

@Component({})
export default class ClipCommentsComponent extends Vue {
  get annotations(): TimeStampMessage[] {
    return ProjectModule.currentClipChangeList
  }

  public isComment(a: TimeStampMessage): boolean {
    return a.type === 'comment'
  }

  public hasDataUrl(a: TimeStampMessage): boolean {
    return isDrawingMessage(a) && !!a.dataUrl
  }

  public handleDeleteAnnotation(id: string, type: AnnotationType): void {
    ProjectModule.deleteAnnotationFromCurrentClip({id, type})
  }

  public colors(userName: string): { [key: string]: boolean } {
    return colorClasses(userName)
  }

  public toggleAnnotation(a: TimeStampMessage): void {
    if (isDrawingAnnotation(a)) {
      handleDrawAnnotationClick(a)
    } else if (isCommentAnnotation(a)) {
      handleCommentAnnotationClick(a)
    } else {
      log.warn('Cannot toggle annotation: Type of TimeStampMessage not recognized')
    }
  }
}
