





















































































import {Component, Prop, Ref, Vue, Watch} from 'vue-property-decorator'
import {CommentModule, DrawModule, ProjectModule, VideoModule} from '@/store'
import {Clip, TimeStampMessage, Comment, Drawing} from '@/store/models/Project'
import PlayerComponent from '@/components/player/Player.vue'
import DrawingCanvasComponent from '@/components/drawings/DrawingCanvas.vue'
import DrawButtonLine from '@/components/drawings/buttons/DrawButtonLine.vue'
import DrawButtonOval from '@/components/drawings/buttons/DrawButtonOval.vue'
import DrawButtonPolyline from '@/components/drawings/buttons/DrawButtonPolyline.vue'
import DeleteButtonComponent from '@/components/drawings/buttons/DeleteButton.vue'
import ClipTitleBarComponent from '@/components/ClipTitleBar.vue'
import AnnotateControlsComponent from '@/components/player/AnnotateControls.vue'
import PlayerProgressBarComponent from '@/components/player/PlayerProgressBar.vue'
import PlayerControlsComponent from '@/components/player/PlayerControls.vue'
import CommentBoxComponent from '@/components/comments/CommentBox.vue'
import CommentBoxReadOnlyComponent from '@/components/comments/CommentBoxReadOnly.vue'
import ClipCommentsComponent from '@/components/ClipComments.vue'
import {isCommentAnnotation, isDrawingAnnotation} from '@/common/utils'
import DrawingCanvasReadOnlyComponent from '@/components/drawings/DrawingCanvasReadOnly.vue'

@Component({
  components: {
    player: PlayerComponent,
    'drawing-canvas': DrawingCanvasComponent,
    'drawing-canvas-read-only': DrawingCanvasReadOnlyComponent,
    'line-button': DrawButtonLine,
    'oval-button': DrawButtonOval,
    'freedraw-button': DrawButtonPolyline,
    'delete-button': DeleteButtonComponent,
    'progress-bar': PlayerProgressBarComponent,
    'player-controls': PlayerControlsComponent,
    'annotate-controls': AnnotateControlsComponent,
    'comment-box': CommentBoxComponent,
    'comment-box-read-only': CommentBoxReadOnlyComponent,
    'clip-title-bar': ClipTitleBarComponent,
    'clip-comments': ClipCommentsComponent
  }
})
export default class ProjectComponent extends Vue {
  @Prop() clipId!: string
  @Ref('drawing-canvas') drawingCanvas!: DrawingCanvasComponent
  @Ref('player-canvas') p!: PlayerComponent

  mounted(): void {
    CommentModule.reset()
    DrawModule.reset()
    VideoModule.reset()

    ProjectModule.loadClip({id: this.clipId})
  }

  get drawCanvasWidth(): number {
    return DrawModule.canvasWidth
  }

  get drawCanvasHeight(): number {
    return DrawModule.canvasHeight
  }

  get drawingStateClassObject(): { [name: string]: boolean } {
    return {
      'drawing-canvas-active': this.isDrawingEnabledState
    }
  }

  @Watch('videoTime')
  public handleCommentPlayback(timeValue: number): void {
    if (this.isPlaying) {
      // show comments if in range
      // hide comments if not in range
      ProjectModule.rollPlaybackAnnotations({time: timeValue})
    }
  }

  @Watch('isPlaying')
  public handleStopPlayback(isPlaying: boolean): void {
    if (!isPlaying) {
      // hide annotations for playback
      ProjectModule.resetRollingAnnotations()
    }
  }

  get isPlaying(): boolean {
    return VideoModule.isPlaying
  }

  get videoTime(): number {
    return VideoModule.currentTime
  }

  get isCommentBoxEnabledState(): boolean {
    return ProjectModule.canvasMode === 'activated-comment'
  }

  get clip(): Clip | undefined {
    return ProjectModule.clips.find(p => p.id === this.clipId)
  }

  get link(): string {
    return this.clip?.link || ''
  }

  get isDrawingEnabledState(): boolean {
    return ProjectModule.canvasMode === 'activated-draw'
  }

  get rollingComments(): Comment[] {
    return ProjectModule.rollingAnnotations.filter(isCommentAnnotation)
  }

  get rollingDrawings(): Drawing[] {
    return ProjectModule.rollingAnnotations.filter(isDrawingAnnotation)
  }

  public stopAnnotating(): Promise<void> {
    return DrawModule.stopAnnotating()
  }

  public stopCommenting(): Promise<void> {
    return CommentModule.stopAnnotating()
  }

  public togglePlay(): void {
    if (!this.p.player) {
      return
    }
    if (this.p.player.paused || this.p.player.ended) {
      this.p.player.play()
    } else {
      this.p.player.pause()
    }
  }
}
