















import {Component, Vue} from 'vue-property-decorator'
import {CommentModule, DrawModule, ProjectModule, VideoModule} from '@/store'
import {v4} from 'uuid'
import {DrawStyle} from '@/components/fabric/DrawStyle'
import {emitter, Events, handleStopDrawingAnnotate} from '@/common/events'

@Component({})
export default class AnnotateControlsComponent extends Vue {
  public async toggleAnnotate(): Promise<void> {
    const mode = ProjectModule.canvasMode
    switch (mode) {
      case 'deactivated':
        await this.handleAnnotate()
        break
      case 'activated-comment':
        await this.handleStopComment()
        await this.handleAnnotate()
        break
      case 'activated-draw':
        await handleStopDrawingAnnotate()
        break
    }
  }

  public async toggleComment(): Promise<void> {
    const mode = ProjectModule.canvasMode
    switch (mode) {
      case 'deactivated':
        await this.handleComment()
        break
      case 'activated-comment':
        await this.handleStopComment()
        break
      case 'activated-draw':
        await handleStopDrawingAnnotate()
        await this.handleComment()
        break
    }
  }

  public async handleAnnotate(): Promise<void> {
    await VideoModule.pauseVideo()
    DrawModule.setDrawStyle(DrawStyle.Polyline)
    ProjectModule.setCanvasMode('activated-draw')
  }

  public async handleComment(): Promise<void> {
    await VideoModule.pauseVideo()
    CommentModule.setCurrentCommentId({commentId: v4()})
    ProjectModule.setCanvasMode('activated-comment')
  }

  public async handleStopComment(): Promise<void> {
    if (CommentModule.currentComment) {
      await CommentModule.stopAnnotating()
    } else {
      CommentModule.reset()
    }
  }
}
