





import {Component, Prop, Vue} from 'vue-property-decorator'
import {Comment} from '../../store/models/Project'

@Component({})
export default class CommentBoxReadOnlyComponent extends Vue {
  @Prop({default: {text: '', id: '0'} as Comment}) comment!: Comment;
}
