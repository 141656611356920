





























import {Component, Prop, Ref, Vue} from 'vue-property-decorator'
import {CommentModule, ProfileModule, ProjectModule} from '@/store'
import Button from '@/components/buttons/Button.vue'

@Component({
  components: {Button}
})
export default class CommentBoxComponent extends Vue {
  @Prop({default: false}) isReadOnly!: boolean
  @Ref('comment-box') commentBoxEl: HTMLTextAreaElement | undefined

  public showNameBox = false

  mounted() {
    const name = ProfileModule.name
    if (!name) {
      this.showNameBox = true
    } else {
      this.showNameBox = false
    }

    this.$nextTick(() => {
      this.focus()
    })
  }

  get comment(): string {
    return CommentModule.currentComment
  }

  set comment(comment: string) {
    CommentModule.setCurrentComment({comment})
  }

  get name(): string {
    return ProfileModule.name
  }

  set name(n: string) {
    ProfileModule.setName(n)
  }

  public focus(): void {
    if (this.commentBoxEl) {
      this.commentBoxEl.focus()
    }
  }

  public handlePostComment(): void {
    CommentModule.stopAnnotating()
  }

  public handleDeleteComment(): void {
    const commentId = CommentModule.currentCommentId
    if (commentId) {
      ProjectModule.deleteAnnotationFromCurrentClip({id: commentId, type: 'comment'})
    }

    CommentModule.cancelAnnotating()
  }
}
